import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import NotFound from "./pages/NotFound/NotFound";
import Intro from "./pages/Intro/IntroLissac";
import IntroOptic2000 from "./pages/Intro/IntroOptic2000";
import Home from "./pages/Home/Home";
import Chapter from "./pages/Chapter/Chapter";
import classes from "./App.module.css";
import BrandSelection from "./pages/BrandSelection/BrandSelection";
import LogbookFormContext from "./context/logbookForm-context";
import { useContext } from "react";
import { ActionPlan } from "./pages/ActionPlan";

import {
  actionplanPath,
  brandPath,
  chapterPath,
  homePath,
  introOptic2000Path,
  introPath,
  loginPath,
  statisticPath,
} from "./routes";
import { Card } from "./pages/Card";
import Statistic from "./pages/Statistiques/Statistic";
import { useUser } from "./hooks/useUser";

const App = () => {
  const { chapter } = useContext(LogbookFormContext);
  const { data, isFetched } = useUser();

  return (
    <Box className={classes.mainContainer}>
      {data ? (
        <Routes>
          <Route path={loginPath} element={<BrandSelection />} />
          <Route path={brandPath} element={<BrandSelection />} />
          <Route path={homePath} element={<Card />}>
            <Route index element={<Home />} />
            {chapter.chapters.map((chapterData) => (
              <Route
                key={chapterData.id}
                path={`${chapterPath}/${chapterData.id}/*`}
                element={<Chapter data={chapterData} />}
              />
            ))}
            <Route path={actionplanPath} element={<ActionPlan />} />
          </Route>
          <Route path={introPath} element={<Intro />} />
          <Route path={introOptic2000Path} element={<IntroOptic2000 />} />
          <Route path="*" element={<NotFound />} />
          <Route path={statisticPath} element={<Statistic />} />
        </Routes>
      ) : (
        <p>Loading...</p>
      )}
    </Box>
  );
};

export default App;
