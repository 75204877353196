import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import LogbookActivityContext from "../../context/logbookActivity-context";
import classes from "./Intro.module.css";
import { useParams } from "react-router-dom";

const Intro = () => {
  // eslint-disable-next-line
  const { code, brand } = useParams();
  const { activity } = useContext(LogbookActivityContext);
  const navigate = useNavigate();

  const readIntroHandler = () => {
    localStorage.setItem("hasReadIntro", true);
    navigate(`/home/${code}/`);
  };

  return (
    <Container>
      <div className={classes.bolcImg}>
        <img
          className={classes.imgBandeau}
          src={"/images/carnet-de-bord-bandeau.svg"}
          alt="Logo intro"
        />
        <br />
        <img
          className={classes.img}
          src={"/images/carnet-de-bord-appli.svg"}
          alt="Logo intro"
        />
      </div>
      <Card className={classes.introCard}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Cher(e) partenaire,
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            Notre environnement métier ne cesse d’évoluer et vous demande des
            capacités d’adaptation toujours plus fortes. Afin de continuer
            d’accroître nos parts de marché, il nous apparaît important de
            prendre le temps de faire une analyse synthétique de vos entreprises
            1 fois par an avec cet outil appelé CARNET DE BORD qui s’appuie sur
            4 piliers fondamentaux :
            <div>
              <br />
            </div>
            <ul>
              <li>l’Humain : votre équipe, la force vive de votre magasin.</li>
              <li>
                Le Management qui vous permettra de fédérer, d’animer et de
                fidéliser vos collaboratrices et collaborateurs.
              </li>
              <li>
                Le Business pour prendre des parts marché et s’assurer de la
                rentabilité de vos entreprises.
              </li>
              <li>
                Enfin l’évidence : Le client à travers la qualité de services.
              </li>
            </ul>
            Ce carnet de bord est votre outil... il doit permettre d’analyser
            votre situation et ainsi de mettre en place des objectifs adaptés et
            conquérants. A vous de jouer !
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            className={classes.authors}
          >
            <div>
              <strong>Jean-luc FAVENE</strong>
              <br />
              <span>Directeur d’Enseigne</span>
            </div>
            <div>
              <strong>Anne-Charlotte GUILLAUD</strong>
              <br />
              <span>Responsable Réseau Franchise</span>
            </div>
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button variant="contained" onClick={readIntroHandler}>
            {!!activity && activity.answers ? "Continuer" : "Commencer"}
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Intro;
