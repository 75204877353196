import { useContext, useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";

import ChapterCard from "../../components/Chapter/ChapterCard";

import LogbookActivityContext from "../../context/logbookActivity-context";

import classes from "./Home.module.css";
import { useNavigate, useParams } from "react-router-dom";
import LogbookFormContext from "../../context/logbookForm-context";
import { actionplanPath } from "../../routes";

const Home = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const handleActionPlan = () => {
    navigate(`/home/${code}/${actionplanPath}`);
  };
  const { chapter, year } = useContext(LogbookFormContext);
  const { activity, activityProgress, fetchData } = useContext(
    LogbookActivityContext
  );

  const chaptersProgress = Object.keys(activityProgress).reduce(
    (acc, questionId) => {
      const pathElts = questionId.split("_");
      if (pathElts.length === 0) return acc;
      const chapterId = pathElts[0];
      if (!acc[chapterId]) {
        acc[chapterId] = { max: 0, current: 0, percent: 0 };
      }
      acc[chapterId].max += activityProgress[questionId].max;
      acc[chapterId].current += activityProgress[questionId].current;
      acc[chapterId].percent =
        (acc[chapterId].current / acc[chapterId].max) * 100;
      return acc;
    },
    {}
  );

  useEffect(() => {
    fetchData(code, year);
  }, [code, year]);
  const isDisabled = !!activity && activity.status === "VALIDATED";

  return (
    <Container>
      {isDisabled && (
        <Typography className={classes.activityMessage}>
          Ce carnet de bord a été validé. Il n&apos;est plus modifiable.
          <Button
            variant="contained"
            onClick={handleActionPlan}
            aria-label={"Plan d'Actions"}
          >
            Plan d&apos;Actions
          </Button>
        </Typography>
      )}

      <Grid container spacing={4}>
        {chapter.chapters.map((chapter) => (
          <Grid item xs={12} md={6} key={chapter.name}>
            <ChapterCard
              data={chapter}
              progress={chaptersProgress[chapter.id]}
              disabled={isDisabled}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
