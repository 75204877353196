import { Container } from "@mui/material";
import { useContext } from "react";
import { Outlet, useLocation, useParams, useRoutes } from "react-router-dom";
import AppBar from "../../components/Layout/AppBar";
import AppNavigation from "../../components/Layout/AppNavigation";
import { LogbookActivityContextProvider } from "../../context/logbookActivity-context";
import LogbookFormContext from "../../context/logbookForm-context";
import { chapterPath } from "../../routes";

export const Card = () => {
  const { code } = useParams();
  const location = useLocation();
  const isChapter = useRoutes([{ path: `/${chapterPath}/*` }], location);
  const { chapter } = useContext(LogbookFormContext);
  const chaptersElements = chapter.chapters.map((chapterData) => ({
    label: chapterData.name,
    icon: chapterData.icon,
    path: `/home/${code}/chapter/${chapterData.id}`,
    color: chapterData.color,
  }));

  if (
    sessionStorage.getItem("code") == null ||
    sessionStorage.getItem("code") === ""
  ) {
    if (window.location.search != null && window.location.search !== "") {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      sessionStorage.setItem("code", code);
    }
  }
  return (
    <>
      <LogbookActivityContextProvider>
        <AppBar title="Carnet de bord" color="primary" />
        <Container sx={{ py: 3 }} disableGutters={true}>
          <Outlet />
        </Container>
        {!!isChapter && <AppNavigation data={chaptersElements} />}
      </LogbookActivityContextProvider>
    </>
  );
};
