import { useState, useCallback, useContext, useEffect } from "react";
import { Container, Box, Drawer, IconButton } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import SurveyJSForm from "../../components/UI/SurveyJSForm";
import ChapterStepper from "../../components/Chapter/ChapterStepper";
import ChapterToolbox from "../../components/Chapter/ChapterToolbox";

import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classes from "./Chapter.module.css";
import ChapterNavigation from "../../components/Chapter/ChapterNavigation";
import LogbookActivityContext from "../../context/logbookActivity-context";

const Chapter = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { code, year } = useParams();
  const location = useLocation();

  const { fetchData, isLoading } = useContext(LogbookActivityContext);
  const chapterData = props.data;
  const chapterId = chapterData.id;
  const chapterPath = `/home/${code}/chapter/${chapterId}/`;
  const navigate = useNavigate();
  let currentStepIndex = +location.pathname.replace(chapterPath, "");
  if (isNaN(currentStepIndex)) currentStepIndex = 0;
  const maxStepIndex = chapterData.form.pages.length;

  window.onload = function (e) {
    navigate(`/home/${code}/`);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const goHandler = useCallback(
    (index) => {
      if (index < 0 && index >= maxStepIndex) return;
      navigate(chapterPath + index);
    },
    [chapterPath, maxStepIndex, navigate]
  );

  const nextHandler = useCallback(() => {
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex >= maxStepIndex) return;
    navigate(chapterPath + nextStepIndex);
  }, [chapterPath, currentStepIndex, maxStepIndex, navigate]);

  const previousHandler = useCallback(() => {
    const previousStepIndex = currentStepIndex - 1;
    if (previousStepIndex < 0) return;
    navigate(chapterPath + previousStepIndex);
  }, [chapterPath, currentStepIndex, navigate]);

  const backToHomepageHandler = () => navigate(`/home/${code}`);

  useEffect(() => {
    async function fetchDataFromApi() {
      await fetchData(code);
    }
    fetchDataFromApi();
  }, [code, year]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };
  const chapterNavigation = (
    <ChapterNavigation
      steps={chapterData.form.pages}
      activeStep={currentStepIndex}
      onPreviousStepClick={previousHandler}
      onNextStepClick={nextHandler}
      onCompleteChapterClick={backToHomepageHandler}
      color={chapterData.color}
    />
  );
  if (isLoading) return <>chargement...</>;
  return (
    <Container className={`${classes.chapterContainer} ${chapterData.color}`}>
      {!isMobile && (
        <ChapterStepper
          steps={chapterData.form.pages}
          activeStep={currentStepIndex}
          onStepClick={goHandler}
          onPreviousStepClick={previousHandler}
          onNextStepClick={nextHandler}
          color={chapterData.color}
        />
      )}

      {isMobile && chapterNavigation}

      <Box className={classes.actionContainer}>
        <IconButton
          aria-label="Outils"
          component="span"
          onClick={toggleDrawer(true)}
          color={chapterData.color}
          title="Boîte à outils"
        >
          <HelpCenterIcon fontSize="large" color="inherit" />
        </IconButton>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette[chapterData.color].light,
        }}
        className={classes.formContainer}
      >
        <SurveyJSForm
          currentPage={currentStepIndex}
          currentChapter={chapterData.id}
        />
      </Box>

      {chapterNavigation}

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawer }}
      >
        <Box
          sx={{ width: 250, p: 2 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <ChapterToolbox
            links={chapterData.toolLinks}
            color={chapterData.color}
          />
        </Box>
      </Drawer>
    </Container>
  );
};

export default Chapter;
