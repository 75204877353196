import React from "react";
import { Button } from "@mui/material";

const AppButton = ({ title, className, disabled, onClick }) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {title}
    </Button>
  );
};

export default AppButton;
