import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import LogbookActivityContext from "../../context/logbookActivity-context";
import { actionPlan } from "../../data/logbook/actionPlan/actionPlan";
import * as Survey from "survey-react";
import { updateLogbookActivity } from "../../services/api/backendApi";
import LogbookFormContext from "../../context/logbookForm-context";
import "../../components/UI/SurveyJSForm.custom.css";
import { getThemePercent } from "../../tools";

export const ActionPlan = () => {
  const actionPlanSurvey = new Survey.Model(actionPlan);
  const { activity, activityProgress, fetchData } = useContext(
    LogbookActivityContext
  );

  const { code, year } = useParams();
  useEffect(() => {
    if (code && year) {
      fetchData(code, year);
    }
  }, [code, year, fetchData]);

  useEffect(() => {
    if (activity) {
      const data = {};
      if (activity.answers) {
        activity.answers.forEach((answer) => {
          data[answer.questionId] = answer.answer;
        });
      }
      actionPlanSurvey.data = data;
    }
  }, [activity]);

  const { surveyVersion } = useContext(LogbookFormContext);
  actionPlanSurvey.onValueChanged.add((sender, options) => {
    const questionId = options.name;
    const answer = options.value;
    const answers = {
      questionId,
      answer,
    };
    updateLogbookActivity(
      surveyVersion,
      code,
      year,
      answers,
      getThemePercent(activityProgress)
    );
  });
  return <>{!!activity && <Survey.Survey model={actionPlanSurvey} />}</>;
};
