import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import LogbookActivityContext from "../../context/logbookActivity-context";
import classes from "./Intro.module.css";

const IntroOptic2000 = () => {
  // eslint-disable-next-line
  const { code, brand } = useParams();
  const { activity } = useContext(LogbookActivityContext);
  const navigate = useNavigate();

  const readIntroHandler = () => {
    localStorage.setItem("hasReadIntro", true);
    navigate(`/home/${code}/`);
  };

  return (
    <Container>
      <div className={classes.bolcImg}>
        <img
          className={classes.imgBandeau}
          src="/images/carnet-de-bord-bandeau.svg"
          alt="Logo intro"
        />
        <br />
        <img
          className={classes.img}
          src="/images/carnet-de-bord-appli.svg"
          alt="Logo intro"
        />
      </div>
      <Card className={classes.introCard}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Cher(e) associé(e),
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            Notre environnement métier ne cesse d’évoluer et nous demande des
            capacités d’adaptation toujours plus fortes, alors si nous
            souhaitons continuer d’asseoir notre positionnement de leader
            incontesté du marché de l’Optique, il nous apparaît comme important
            de pouvoir prendre le temps de faire une analyse synthétique de nos
            entreprises 1 fois par an avec ce CARNET DE BORD au travers de 4
            piliers fondamentaux qui sont :{"\n"}
            <ul>
              <li>l’Humain : votre équipe, la force vive de votre magasin.</li>
              <li>
                Le Management qui vous permettra de fédérer, d’animer et de
                fidéliser vos collaboratrices et collaborateurs.
              </li>
              <li>
                Le Business pour prendre des parts marché et s’assurer de la
                rentabilité de vos entreprises.
              </li>
              <li>
                Enfin l’évidence : Le client à travers la qualité de services.
              </li>
            </ul>
            Ce carnet de bord est votre outil... il doit permettre d’analyser
            votre situation et ainsi de mettre en place des objectifs adaptés et
            conquérants. A vous de jouer !
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            className={classes.authors}
          >
            <div>
              <strong>Jean Christophe FELIX</strong>
              <br />
              <span>Administrateur Réseau</span>
            </div>
            <div>
              <strong>Frédéric DOUILLET</strong>
              <br />
              <span>Directeur Réseau</span>
            </div>
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button variant="contained" onClick={readIntroHandler}>
            {!!activity && activity.answers ? "Continuer" : "Commencer"}
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default IntroOptic2000;
